import { SelectColor } from 'components/colors/component'
import { DropdownWithDividersComponent } from 'components/dropdown/component'
import * as React from 'react'
import { Input } from 'reactstrap'
//import { TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'

import { EditSectionProps } from '../components'
import {
  BORDER_FONT_SIZES,
  EmailAttributes,
  FONT_FAMILIES,
  FONT_SIZES,
  FONT_WEIGHTS,
  getAttribute,
  getFontFamily,
  MenuSections
} from '../constants'

interface SectionColorEditorProps {
  data: any,
  label: string,
  onChange?: any,
  type?: string,
}

export const SectionColorEditor = (props: SectionColorEditorProps) => {
  const sizeKey: string = `${props.type}_${EmailAttributes.fontSize}`
  const weightKey: string = `${props.type}_${EmailAttributes.fontWeight}`
  const colorKey: string = `${props.type}_${EmailAttributes.fontColor}`
  return <div className="section">
    <div className="title">{props.label}</div>
    <div className="small-title-description">Font style &amp; color</div>
    <div className="color-editor-wrapper">
      <SelectColor
                key={colorKey}
                className={'secondary'}
                selectColor={(c: string) => props.onChange(colorKey, c)}
                color={getAttribute(props.data, colorKey, '#000000')} />
      <div className="color-editor-drop-downs-wrapper">
        <Input type="select" value={getAttribute(props.data, sizeKey, '12px')}
                            onChange={(e) => props.onChange(sizeKey, e.target.value)}>
          {FONT_SIZES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
        </Input>
        <Input type="select" value={getAttribute(props.data, weightKey, 'normal')}
                              onChange={(e) => props.onChange(weightKey, e.target.value)}>
          {FONT_WEIGHTS.map((s: string) => <option key={s} value ={s}>{s}</option>)}
        </Input>
      </div>
  </div>
  </div>
}

interface ModuleConfigColorEditorProps {
  label: string,
  onChange?: any,
  type?: string,
  getConfig?: any,
}

export const ModuleConfigColorEditor = (props: ModuleConfigColorEditorProps) => {
  const sizeKey: string = `${props.type}FontSize`
  const weightKey: string = `${props.type}FontWeight`
  const colorKey: string = `${props.type}FontColor`
  const borderColorKey: string = `${props.type}BorderColor`
  const borderSizeKey: string = `${props.type}BorderSize`

  return <div className="section">
    <div className="title">{props.label}</div>
    <div className="small-title-description">Font style &amp; color</div>
    <div className="color-editor-wrapper">
      <SelectColor
        key={colorKey}
        className={'secondary'}
        selectColor={(c: string) => props.onChange(colorKey, c)}
        color={props.getConfig(colorKey, '#000000')}/>
      <div className="color-editor-drop-downs-wrapper">
        <Input type="select" value={props.getConfig(sizeKey, '12px')}
               onChange={(e) => props.onChange(sizeKey, e.target.value)}>
          {FONT_SIZES.map((s: string) => <option key={s} value={s}>{s}</option>)}
        </Input>
        <Input type="select" value={props.getConfig(weightKey, 'normal')}
               onChange={(e) => props.onChange(weightKey, e.target.value)}>
          {FONT_WEIGHTS.map((s: string) => <option key={s} value={s}>{s}</option>)}
        </Input>
      </div>
    </div>
    <br/>
    <div className="small-title-description">Border style &amp; color</div>
    <div className="color-editor-wrapper">
      <SelectColor
        key={borderColorKey}
        className={'secondary'}
        selectColor={(c: string) => props.onChange(borderColorKey, c)}
        color={props.getConfig(borderColorKey, '#000000', 'primary_color')}/>
      <div className="color-editor-drop-downs-wrapper">
        <Input type="select" value={props.getConfig(borderSizeKey, '1px')}
               onChange={(e) => props.onChange(borderSizeKey, e.target.value)}>
          {BORDER_FONT_SIZES.map((s: string) => <option key={s} value={s}>{s}</option>)}
        </Input>
      </div>
    </div>
  </div>
}

const EmailFontEditor = (props: SectionColorEditorProps) => {
  const newsletterFontFamily: string = `${EmailAttributes.newsletterFontFamily}`
  return <div className="section">
    <div className="color-editor-drop-downs-wrapper">
      <DropdownWithDividersComponent
        data={FONT_FAMILIES}
        selected={getFontFamily(getAttribute(props.data, newsletterFontFamily))}
        onChange={(e) => props.onChange(newsletterFontFamily, e.target.value)}
      />
    </div>
  </div>
}

export class ColorEdit extends React.Component<EditSectionProps> {
  constructor(props: EditSectionProps) {
    super(props)
  }

  public render() {
    //const templateModules: TemplateModule[] = getAttribute(this.props.data, 'template_modules')
    //const hasSectionsAvailable = templateModules.filter((x) => x.type === TemplateModuleType.section).length > 0
    return <div className="color-editor">
      <span className="title">{MenuSections.fontAndColor}</span>
      <div className="color-section">
      <div className="block">
      <div className="section">
        <br></br>
        <EmailFontEditor    label="Font"
                            onChange={(k: string, v: string) => this.props.onChange(k, v)}
                            data={this.props.data}/>
      </div>
        </div>
        <div className="block">
          <div className="small-title">
            Primary Color
          </div>
          <SelectColor
                key={EmailAttributes.primaryColor}
                className={'primary'}
                selectColor={(c: string) => this.props.onChange(EmailAttributes.primaryColor, c)}
                color={this.props.data[EmailAttributes.primaryColor]} />
        </div>
        <div className="block">
          <div className="small-title">
            Secondary Color
            </div>
          <SelectColor
              key={EmailAttributes.secondaryColor}
              className={'secondary'}
              selectColor={(c: string) => this.props.onChange(EmailAttributes.secondaryColor, c)}
              color={this.props.data[EmailAttributes.secondaryColor]} />
        </div>
        <div className="block">
          <div className="small-title">
            Email Body Background Color
            </div>
          <SelectColor
              key={EmailAttributes.emailBodyBackgroundColor}
              className={'emailBodyBackground'}
              selectColor={(c: string) => this.props.onChange(EmailAttributes.emailBodyBackgroundColor, c)}
              color={this.props.data[EmailAttributes.emailBodyBackgroundColor]} />
        </div>
      </div>
      <div className="section">
        <br></br>
        <SectionColorEditor type="source"
                            label="Source Title"
                            onChange={(k: string, v: string) => this.props.onChange(k, v)}
                            data={this.props.data}/>
      </div>
      <div className="section">
      <br></br>
        <SectionColorEditor type="title"
                            label="Article Title"
                            onChange={(k: string, v: string) => this.props.onChange(k, v)}
                            data={this.props.data}/>
      </div>
      <div className="section">
      <br></br>
        <SectionColorEditor type="description"
                            label="Article Description"
                            onChange={(k: string, v: string) => this.props.onChange(k, v)}
                            data={this.props.data}/>
      </div>
      {/* {hasSectionsAvailable &&
      <div className="section">
      <br></br>
        <SectionColorEditor type="section"
                            label="Section Header"
                            onChange={(k: string, v: string) => this.props.onChange(k, v)}
                            data={this.props.data}/>
      </div>} */}
    </div>
  }
}
