import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { isTruthy } from 'generic/utility'
import { isNil } from 'lodash'
import * as React from 'react'
import { Input } from 'reactstrap'
import { EmailAttributes } from 'shared_server_client/constants'
import { SectionConfig, TemplateModule } from 'shared_server_client/types/email_layout'
import { EditSectionProps } from '../components'
import {
  getAttribute,
  MenuSections,
} from '../constants'
import '../styles.scss'
import { ModuleConfigColorEditor } from './colors'
import { ModuleAction } from './templateModule'

interface SECProps extends EditSectionProps {
  context?: any,
  staticTextType: string,
}

interface SectionEditorExtraProps extends TemplateModule {
  selectSection?: any,
  onModulePropsChange?: any,
}

type SectionEditorProps = GenericRedux.AllComponentPropsWithModal<any> & SECProps & SectionEditorExtraProps
export class SectionEditor extends React.Component<SectionEditorProps, any> {
  public static contextType = RasaContext
  constructor(props: any) {
    super(props)
    this.handleModuleChange = this.handleModuleChange.bind(this)
    this.getModuleConfig = this.getModuleConfig.bind(this)
  }

  public render() {
    const { context } = this.props
    const isShowSectionHeader: boolean =
      isTruthy(this.getModuleConfig(SectionConfig.SECTION_SHOW_HEADER, true))
    return context ? <div className="static-content-wrapper">
    <div className="go-back" onClick={() => this.goBack()}>&lt; &nbsp; Go back</div>
    <div className="title-description-and-toggle-container">
      <div className="title-and-toggle-container">
        <div className="title">
          {`${this.getSectionNameById(context.moduleConfig.sectionId)}`}
        </div>
      </div>
      <div className="block">
          <div className="title">
            Maximum article count
          </div>
          <div className="description">
            What is the maximum number of articles that you want to display?
          </div>
          <Input value={this.getModuleConfig('count', this.props.context.moduleConfig.count)}
                 min={1}
                 max={15}
                 type="number"
                 name={EmailAttributes.maxArticles}
                 onChange={(e) => {this.handleModuleChange('count', e.target.valueAsNumber)}}/>
      </div>
      <div className="section">
        <div className="block">
          <div className="toggle-active"
               onClick={() => this.handleModuleChange(SectionConfig.SECTION_SHOW_HEADER, !isShowSectionHeader)}>
            <button className="toggle-button"
                    style={{background: 'none', border: 'none', outline: 'none'}}>
              {isShowSectionHeader ? <ToggleOn/> : <ToggleOff/> }
            </button>
            <span className="small-description">Show Section Header</span>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="block read-more-text">
          <div className="title">
            Read more
          </div>
          <div className="url-rectangle-wrapper">
            <div className="url-left-element-text">
              TEXT
            </div>
            <div className={'url-right-element'}>
              <Input value={this.getModuleConfig('readMore', getAttribute(this.props.data, EmailAttributes.readMoreText) || '')}
                     onChange={(e) => this.handleModuleChange('readMore', e.target.value)}
                     placeholder={'Read more'}/>
              <br></br>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-color-editor">
        <br></br>
        <ModuleConfigColorEditor type="section"
          getConfig={this.getModuleConfig}
          label="Section Header"
          onChange={(k: string, v: string) => this.handleModuleChange(k, v)} />
      </div>
    </div>
        </div> : <div></div>
  }

  private getSectionNameById = (sectionId): string => {
    // I think we would always have sections if we come here
    if (this.props.data.sections && this.props.data.sections.length > 0) {
      const currentSection = this.props.data.sections.find((x) => x.id === sectionId)
      return currentSection ? currentSection.name : null
    }
    return null
  }

  private goBack() {
    this.props.selectSection(MenuSections.layout)
  }
  private getModuleConfig = (config: string, defaultValue: any = '', defaultConfig: string = '') => {
    const templateModule = this.getCurrentTemplateModule()
    if (templateModule.moduleConfig && !isNil(templateModule.moduleConfig[config])) {
      return templateModule.moduleConfig[config]
    }

    if (defaultConfig && !isNil(this.props.data[defaultConfig])) {
      return this.props.data[defaultConfig]
    }

    return defaultValue
  }

  private getCurrentTemplateModule = () => {
    return this.props.context ? this.props.data.template_modules.filter((x) => x.type === this.props.context.type
      && x.sequence === this.props.context.sequence)[0] || this.props.context : {}
  }

  private handleModuleChange = (config: string, value: any) => {
    const templateModule: TemplateModule = this.getCurrentTemplateModule()
    templateModule.moduleConfig[config] = value
    this.props.onModulePropsChange({
      action: ModuleAction.edit,
      module: templateModule,
    })
    const moduleconfig = this.props.data.sections.find((x) => x.id === templateModule.moduleConfig.sectionId)
    if (moduleconfig && moduleconfig.category_type_name != null && config === 'count') {
      this.props.propertyChanged(`newsbrief_count_${moduleconfig.category_type_name.toLowerCase()}`, value)
      this.props.propertyChanged(`max_lead_count_${moduleconfig.category_type_name.toLowerCase()}`, value)
      this.props.propertyChanged(`max_per_source_${moduleconfig.category_type_name.toLowerCase()}`, value)
      this.props.propertyChanged(`max_preferred_count_${moduleconfig.category_type_name.toLowerCase()}`, value)
      this.props.propertyChanged(`minimum_newsbrief_count_${moduleconfig.category_type_name.toLowerCase()}`,
        this.getModuleConfig(`minimum_newsbrief_count_${moduleconfig.category_type_name.toLowerCase()}`, 0)
      )
    }
  }
}
