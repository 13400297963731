import classnames from 'classnames'
import { SourceSection } from 'components/content-pool/types'
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import * as Router from 'router'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ArticlePool, EditSectionProps } from '../components'
import { EmailAttributes, getAttribute } from '../constants'
import * as Constants from '../constants'
import { articleSelectionForSubjectLinePreview, mergeTokens } from '../utils'

interface SendFromState {
  authenticatedDomains: any[]
  customPreviewText: string,
  date: boolean,
  generatedSubjectLine: string,
  generatedPreviewText: string,
  isLoading: boolean,
  person: any,
  previewText: string,
  sections?: DropdownOption[],
  selectedSection?: DropdownOption,
  sendFromDomain: string,
  sendFromMailbox: string,
  subject: string,
  subjectType: string,
  customSubject: string,
}

type SendFromProps = GenericRedux.AllComponentPropsWithModal<any> & EditSectionProps & {
  articlePool: ArticlePool,
}

const DEFAULT_SENDFROM_DOMAIN = 'sentwithrasa.io'
class SendFromComponent extends React.Component<SendFromProps, SendFromState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: SendFromProps) {
    super(props)
    const parts = this.emailAddressParts()
    this.state = {
      authenticatedDomains: [],
      customPreviewText: (getAttribute(this.props.data, EmailAttributes.previewText) || '' ),
      date: false,
      generatedSubjectLine: '',
      generatedPreviewText: '',
      isLoading: true,
      person: null,
      previewText: 'custom',
      sections: [],
      sendFromDomain: parts[1] || DEFAULT_SENDFROM_DOMAIN,
      sendFromMailbox: parts[0],
      subject: this.getSubject(),
      subjectType: 'custom',
      customSubject: this.getCustomSubject(),
    }
    this.getSections = this.getSections.bind(this)
  }

  public componentDidMount() {
    this.updateSubjectLineAndPreview()
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.setState({person})
      return Promise.all([
        this.getSections(activeCommunity.communityId),
        new Dataset().loadCommunityDataset('emailDomains', activeCommunity.communityId)
      ]).then(([_, emailDomains]) => {
        const authenticatedDomains =
          emailDomains[0].filter((e: any) => e.status === 'valid')
            .map((e: any) => e.domain)
        if (authenticatedDomains.length > 0) {
          if (this.state.sendFromDomain && this.state.sendFromDomain !== DEFAULT_SENDFROM_DOMAIN) {
            this.setState({
              authenticatedDomains: authenticatedDomains.concat([DEFAULT_SENDFROM_DOMAIN]),
            })
          } else {
            this.setState({
              authenticatedDomains: [DEFAULT_SENDFROM_DOMAIN].concat(authenticatedDomains),
            })
          }
        }
        this.updateSubjectLineAndPreview()
      })
    })
    .then(() => this.setState({isLoading: false}))
  }

  public componentDidUpdate(oldProps: SendFromProps){
    if(oldProps.data.email_subject !== this.props.data.email_subject
      || oldProps.data.category_type_id_for_subject !== this.props.data.category_type_id_for_subject
      || oldProps.data.isCustomSubjectType !== this.props.data.isCustomSubjectType
      || oldProps.data.preview_text !== this.props.data.preview_text
    ){
      this.updateSubjectLineAndPreview()
    }
  }

  public render() {
    return this.state.isLoading
    ? <Loading size="64"/>
    : this.renderEditor()
  }

  private updateSubjectLineAndPreview(){
    let isCustomSubjectType: boolean = true
    if (this.props.data.email_subject && this.props.data.email_subject.includes('__DATE__')) {
      this.setState({date: true})
    }
    if (!this.props.data.email_subject || this.props.data.email_subject.includes(Constants.AI_RECOMMENDED_SUBJECT)) {
      this.setState({subjectType: 'ai'})
      isCustomSubjectType = false
    }
    if (this.props.data.email_subject && this.props.data.email_subject.includes(Constants.BOOSTED_SUBJECT)) {
      this.setState({subjectType: 'boosted'})
      isCustomSubjectType = false
    }
    if (this.props.data.category_type_id_for_subject) {
      this.setState({
        selectedSection: this.state.sections.find((x) => x.key === parseInt(this.props.data.category_type_id_for_subject, 10)),
      })
    }

    if (isCustomSubjectType) {
      this.setState({subject: this.getCustomSubject()})
      this.setState({subjectType: 'custom'})
    }

    if (!this.props.data.preview_text || this.props.data.preview_text === Constants.AI_RECOMMENDED_PREVIEW) {
      this.setState({previewText: 'ai'})
      this.setState({customPreviewText: ''})
    }

    if(this.props.data.preview_text !== Constants.AI_RECOMMENDED_PREVIEW){
      this.setState({previewText: 'custom'})
      this.setState({customPreviewText: (getAttribute(this.props.data, EmailAttributes.previewText) || '' )})
    }

    this.setState({
      generatedSubjectLine: this.generateSubjectAndPreview(this.props.data.email_subject),
      generatedPreviewText: this.generateSubjectAndPreview(this.props.data.preview_text || Constants.AI_RECOMMENDED_PREVIEW),
    })
  }

  private generateSubjectAndPreview = (text: string) => {
    const { data, articlePool } = this.props
    const { company_name, email_subject } = data
    const { person, selectedSection } = this.state
    const article = articleSelectionForSubjectLinePreview(articlePool, email_subject, selectedSection ? selectedSection.description : null)
    return mergeTokens(text, article, company_name, person ? person.firstName : '', new Date())
  }

  private renderEditor() {
    return <div className="send-from-editor">
              <span className="title">Send From</span>
              <div className="section">
                <div className="block">
                  <br></br>
                  <div className="sendfrom-title">
                    <div className="select-header">
                      Subject Line
                    </div>
                    <div className="flex-row">
                      <Input className="date-select" type="select" value={this.state.subjectType}
                                      onChange={(e) => this.toggleSubject(e.target.value)}>
                        <option value="custom" key="custom">
                          Custom
                        </option>
                        <option value="ai" key="ai">
                          AI generated
                        </option>
                        <option value="boosted" key="boosted">
                          Boosted article
                        </option>
                      </Input>
                      <DropdownComponent data={Constants.DATE_FORMAT_OPTIONS}
                        selected={getAttribute(this.props.data, EmailAttributes.subjectDateFormat)}
                        onChange={(e: any) => {
                          this.props.onChange(EmailAttributes.subjectDateFormat, e.selected.value)
                          this.toggleSubjectDate(e)
                        }}/>
                    </div>
                    {this.state.sections.length && this.state.subjectType !== 'custom' ?
                    <div className="flex-row section-ddl">
                      <DropdownComponent
                        data={this.state.sections}
                        selected={getAttribute(this.props.data, EmailAttributes.categoryTypeIdForSubject)}
                        onChange={this.onDropdownChange}/>
                    </div>
                    : null}
                    {this.state.subjectType === 'custom' &&
                    <div className="top-space">
                      <Input value={this.state.subject || ''}
                            placeholder="Write your custom subject line"
                            onChange={(e) => this.writeCustomSubject(e.target.value)}/><br />
                    </div>
                    }
                    {this.state.subjectType === 'ai' &&
                    <div className="ai-generating top-space">
                      Personalized article title
                    </div>
                    }
                    {this.state.subjectType === 'boosted' &&
                    <div className="ai-generating top-space">
                      Boosted article title
                    </div>
                    }
                    <div className="sample-title-wrapper">
                      <p className="sample-title">
                        <span className="example-title">
                          {this.state.generatedSubjectLine}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <br></br>
                  <div className="sendfrom-preview-text">
                    <div className="select-header">
                      Preview Text
                    </div>
                    <div className="flex-row">
                      <Input className="date-select"
                            type="select"
                            value={this.state.previewText}
                            onChange={(e) => this.togglePreviewText(e.target.value)}>
                        <option value="custom" key="custom">
                          Custom
                        </option>
                        <option value="ai" key="ai">
                          AI generated
                        </option>
                      </Input>
                    </div>
                    {this.state.previewText === 'custom' &&
                    <div className="top-space">
                      <Input value={this.state.customPreviewText || ''}
                            onChange={(e) => this.writeCustomPreviewText(e.target.value)}/><br />
                    </div>
                    }
                    {this.state.previewText === 'ai' &&
                    <div className="ai-generating top-space">
                      Personalized article description
                    </div>
                    }
                    <div className="sample-title-wrapper">
                      <p className="sample-title">
                        <span className="example-title">
                          {this.state.generatedPreviewText}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={getAttribute(this.props.data, EmailAttributes.communityPartnerCode, 'All') !== 'All' ? 'rasa-noclick' : ''}>
                  <div className="company-name-editor">
                    <div className="section">
                      <div className="block">
                        <div className="select-header">
                          From Name
                        </div>
                        <div className="title">
                          <Input value={getAttribute(this.props.data, EmailAttributes.fromName) || ''}
                                 onChange={(e) => this.props.onChange(EmailAttributes.fromName, e.target.value)} />
                        </div>
                        <br></br>
                      </div>
                    </div>
                  </div>
                  <div className="company-address-editor sendfrom-footer">
                    <div className="section">
                      <div className="block">
                        <div className="title">
                          From Email Address
                        </div>
                        <div className="email-flex">
                          <Input
                            className={classnames('email-input1', this.state.sendFromMailbox ? '' : 'input-required')}
                            value={this.state.sendFromMailbox}
                            onChange={(e) => this.changeMailbox(e)}/>
                          <span className="at-sign">
                            @
                          </span>
                          {
                            this.state.authenticatedDomains.length > 0 ?
                            <div>
                              <Input type="select"
                                    className="email-input2"
                                    value={this.state.sendFromDomain}
                                    onChange={(e) => this.changeDomain(e.target.value)}>
                                {this.state.authenticatedDomains.map((d: string, i) => {
                                  return (
                                    <option key={d + i} value={d}>{d}</option>
                                  )
                                })}
                              </Input>
                            </div>
                            :
                            <div className="standard-domain-text">
                              {this.state.sendFromDomain || DEFAULT_SENDFROM_DOMAIN}
                            </div>
                          }
                        </div>
                        <div onClick={() =>
                          this.props.push('/settings/domain')}>
                          <br/>
                          <a href="#">Authenticate Your Domain</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </div>
  }

  private getSections = (community) => {
    return new Dataset()
      .loadCommunityDataset('sections', community)
    .then((response) => {
      const sections = this.normalizeSections(response[0] || [])
      const selectedSection = sections.find((x) => x.key ===
        Number(this.props.data.category_type_id_for_subject || -1))
      this.setState({
        sections,
        selectedSection,
      })
    })
  }

  private onDropdownChange = (e) => {
    this.setState({selectedSection: e.selected})
    this.props.onChange(EmailAttributes.categoryTypeIdForSubject, e.selected.key)
  }

  private normalizeSections = (sections: SourceSection[]): DropdownOption[] => {
    const normalizedSections = sections.map((s) => ({
      description: s.name,
      key: s.category_type_id,
      value: s.category_type,
    }))
    if (sections.length) {
      if (this.props.data.minimum_newsbrief_count !== 0) {
        normalizedSections.unshift({description: 'No section', key: 0, value: null})
      }
      normalizedSections.unshift({description: '-- Select --', key: -1, value: null})
    }
    return normalizedSections
  }

  private getSubject = () => {
    const subject = (getAttribute(this.props.data, EmailAttributes.emailSubject) || '').split('|')
    if (subject && subject[0] !== Constants.DATE) {
      return subject[0]
    } else {
      return ''
    }
  }

  private getCustomSubject = () => {
    const originalSubject = (getAttribute(this.props.data, EmailAttributes.emailSubject) || '')
    const subject = originalSubject.split('|')
    if (subject && subject[0] !== Constants.DATE &&
       !(this.props.data.email_subject.includes(Constants.BOOSTED_SUBJECT) ||
         this.props.data.email_subject.includes(Constants.AI_RECOMMENDED_SUBJECT))) {
          //we are fetching Subject details. If Subject is separated by '|',
          //then we need to skip __DATE__ from second subject string as well.
          if (subject.length > 1) {
            return originalSubject.replace(Constants.WITH_DATE, '')
              .replace(Constants.DATE, '')
          } else {
            return subject[0]
          }
    } else {
      return ''
    }
  }

  private writeCustomSubject = (s: string) => {
    this.setState({subject: s})
    this.setState({customSubject: s})
    if (s) {
      if (this.state.date) {
        this.props.onChange(EmailAttributes.emailSubject, s + Constants.WITH_DATE)
      } else {
        this.props.onChange(EmailAttributes.emailSubject, s)
      }
    } else {
      if (this.state.date) {
        this.props.onChange(EmailAttributes.emailSubject, Constants.DATE)
      } else {
        this.props.onChange(EmailAttributes.emailSubject, '')
      }
    }
  }

  private toggleSubject = (s: string) => {
    this.setState({subjectType: s})
    const withDate = this.state.date ? Constants.WITH_DATE : ''
    if (s === 'ai') {
      this.props.onChange(EmailAttributes.emailSubject, Constants.AI_RECOMMENDED_SUBJECT + withDate)
      this.setState({
        subject: Constants.AI_RECOMMENDED_SUBJECT,
        subjectType: 'ai',
      })
    } else if (s === 'boosted') {
      this.props.onChange(EmailAttributes.emailSubject, Constants.BOOSTED_SUBJECT + withDate)
      this.setState({
        subject: Constants.BOOSTED_SUBJECT,
        subjectType: 'boosted',
      })
    } else {
      // choosing custom
      this.setState({subject: this.state.customSubject, subjectType: 'custom'})
      this.props.onChange(EmailAttributes.emailSubject, this.state.customSubject + withDate)
    }
  }

  private writeCustomPreviewText = (s: string) => {
    this.setState({customPreviewText: s})
    this.props.onChange(EmailAttributes.previewText, s)
  }

  private togglePreviewText = (s: string) => {
    this.setState({previewText: s})
    if (s === 'ai') {
      this.props.onChange(EmailAttributes.previewText, Constants.AI_RECOMMENDED_PREVIEW)
    } else {
      // choosing custom
      this.setState({customPreviewText: ''})
      this.props.onChange(EmailAttributes.previewText, '')
    }
  }

  private toggleSubjectDate = (e: any) => {
    const newDate = e.selected.value !== Constants.NO_DATE ? Constants.DATE : ''
    if (this.state.subject) {
      this.props.onChange(EmailAttributes.emailSubject, `${this.state.subject}${newDate ? Constants.WITH_DATE : ''}`)
    } else {
      this.props.onChange(EmailAttributes.emailSubject, newDate)
    }
    this.setState({ date: !!newDate })
  }

  private changeMailbox = (e: any) => {
    const value = e.target.value.replace(/[@\s]/g, '')
    this.setState({
      sendFromMailbox: value,
    })
    this.changeEmail(value, this.state.sendFromDomain)
    if (value) {
      this.props.setSectionValid(this.props.name, true)
    } else {
      this.props.setSectionValid(this.props.name, false)
    }
  }

  private changeDomain = (s: string) => {
    this.setState({
      sendFromDomain: s,
    })
    this.changeEmail(this.state.sendFromMailbox, s)
  }

  private changeEmail = (mailbox: string, domain: string) => {
    const newEmail = domain ? `${mailbox}@${domain}` : mailbox
    this.props.onChange(EmailAttributes.fromEmail, newEmail)
  }

  private emailAddressParts = (): string[] => {
    return (getAttribute(this.props.data, EmailAttributes.fromEmail) || '').split('@')
  }
}

export const SendFrom = connect(
  null,
  {
    push: Router.push,
  },
)(SendFromComponent)
